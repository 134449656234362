<template>
  <div class="app-container online-info-page">
    <el-form ref="form" :model="form" size="small" :rules="rules" label-width="80px">
      <el-form-item label="流程名称" prop="roleName">
        <el-input
          class="input-750"
          :disabled="!!form.id"
          v-model="form.title"
          placeholder="流程名称"
        />
      </el-form-item>

      <el-form-item label="所属公司" prop="comId">
        <el-select
          class="input-750"
          :disabled="!!form.id"
          v-model="form.comId"
          placeholder="公司"
          @change="changeCompany"
        >
          <el-option v-for="item in companyList" :label="item.companyName" :value="item.comId" />
        </el-select>
      </el-form-item>

      <el-form-item label="描述" prop="remark">
        <el-input type="textarea" class="input-750" v-model="form.remark" placeholder="流程描述" />
      </el-form-item>

      <el-form-item label="审批规则" prop="auditType">
        <el-radio-group v-model="form.auditType">
          <el-radio :label="1">固定审批流</el-radio>
          <el-radio :label="2">授权审批人</el-radio>
        </el-radio-group>
      </el-form-item>

      <template v-if="form.auditType === 1">
        <el-form-item v-for="(item, idx) in form.stepList">
          <el-row>
            <el-col :span="1"> 第{{ idx + 1 }}级 </el-col>
            <el-col :span="3">
              <el-select
                class="w100"
                placeholder="审批节点类型"
                v-model="form.stepList[idx].nodeType"
              >
                <el-option label="指定用户会签" :value="1" />
                <el-option label="指定用户或签" :value="2" />
              </el-select>
            </el-col>

            <el-col :span="5" :offset="1">
              <el-select
                class="w100"
                multiple
                v-model="form.stepList[idx].assignUserIds"
                placeholder="审批人"
              >
                <el-option
                  v-for="item2 in peopleList"
                  :label="item2.username"
                  :value="item2.userId"
                />
              </el-select>
            </el-col>

            <el-col :span="1" v-if="idx !== 0">
              <el-button
                @click="removeStep(idx)"
                style="margin-left:15px;"
                icon="el-icon-delete"
                circle
              />
            </el-col>
          </el-row>
        </el-form-item>

        <el-form-item prop="auditType">
          <el-button @click="addStep">添加审批层级</el-button>
        </el-form-item>
      </template>
    </el-form>

    <div>
      <div class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';

import { listCityRegions } from '@/api/comm';

import {
  sysCompanyUserList,
  sysCompanyRoleMenuList,
  sysCompanyWorkflowSave,
  sysCompanyWorkflowUpdate,
  sysCompanyRoleDelete,
  sysCompanyWorkflowInfo,
  sysCompanyGetTree
} from '@/api/offline.js';

export default {
  data() {
    return {
      companyList: [],
      checkboxMap: {},
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 总条数
      total: 0,
      // 会员表格数据
      tableData: [],
      // 弹出层标题
      title: '',
      // 是否显示弹出层
      open: false,

      cityList: [],

      // 表单参数
      form: {
        comId: '',
        remark: '',
        auditType: 1,
        stepList: [
          {
            nodeType: 1,
            assignUserIds: []
          }
        ]
      },
      // 表单校验
      rules: {},
      configStatus: 1,
      peopleList: []
    };
  },
  created() {
    // this.getList();
    this.sysCompanyRoleMenuList();
    this.getCompanyList();
    // this.getPeopleList();
  },
  methods: {
    changeCompany() {
      // 编辑以后在获取人员
      _.each(this.form.stepList, (x) => {
        x.assignUserIds = '';
      });
      this.getPeopleList(this.form.comId);
    },
    addStep() {
      this.form.stepList.push({
        nodeType: 1,
        assignUserIds: []
      });
    },
    removeStep(index) {
      this.form.stepList = _.filter(this.form.stepList, (item, idx) => idx !== index);
    },
    getPeopleList(comId) {
      this.loading = true;
      sysCompanyUserList({
        page: 1,
        limit: 10000,
        comId
      }).then((response) => {
        response = response.data;
        this.peopleList = response.list;
      });
    },
    // 获取公司tree数据
    getCompanyList() {
      sysCompanyGetTree().then(({ data }) => {
        this.companyList = data;
        // const firstData = this.companyList[0];
        // setTimeout(() => {
        //   this.$refs.elTree.setCurrentKey(firstData.tid);
        //   this.queryParams.comId = firstData.comId;
        //   this.queryParams.companyName = firstData.companyName || null;
        //   this.getList();
        // }, 800);
      });
    },
    // 获取权限
    sysCompanyRoleMenuList() {
      sysCompanyRoleMenuList().then(({ data }) => {
        this.tableData = this.handleRoleTableData(data);

        const { id } = this.$route.params;
        if (this.$route.params.id !== '-1') {
          setTimeout(() => {
            document.title = '编辑角色';
            this.handleUpdate(id);
          }, 200);
        } else {
          setTimeout(() => {
            document.title = '新增角色';
            this.handleAdd();
          }, 200);
        }

        // handleRoleTableData
      });
    },

    handleRoleTableData(data) {
      const result = [];
      let tempItem;

      _.each(data, (x) => {
        if (x.type === 0) {
          tempItem = {
            label: x.name,
            menuId: x.menuId,
            value: false
          };

          this.checkboxMap[x.menuId] = tempItem;

          result.push({
            name: tempItem,
            modules: [],
            permission: [],
            field: []
          });
        }
      });

      _.each(result, (z) => {
        // 找出所有子层级
        const filters = _.filter(data, (x) => x.parentId === z.name.menuId);
        _.each(filters, (x) => {
          tempItem = {
            label: x.name,
            menuId: x.menuId,
            value: false
          };

          this.checkboxMap[x.menuId] = tempItem;

          if (x.type === 1) {
            z.modules.push(tempItem);
          } else if (x.type === 2) {
            z.permission.push(tempItem);
          } else if (x.type === 3) {
            z.field.push(tempItem);
          }
        });
      });
      return result;
    },
    // 取消按钮
    cancel() {
      this.$router.back();
    },
    // 表单重置
    reset() {
      this.form = {
        comId: '',
        remark: '',
        auditType: 1,
        stepList: [
          {
            nodeType: 1,
            assignUserIds: []
          }
        ]
      };
      this.resetForm('form');
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.open = true;
      this.title = '新增信息';
      if (this.cityList.length > 0) {
        this.form.cityRegion = this.cityList[0].key;
      }
    },
    /** 修改按钮操作 */
    handleUpdate(id) {
      this.reset();
      sysCompanyWorkflowInfo(id).then((res) => {
        // 1固定审批流 需要审批人
        if (`${res.data.auditType}` === '1') {
          _.each(res.data.stepList, (x) => {
            if (x.assignUserIds === '') {
              x.assignUserIds = [];
            } else {
              const ids = _.split(x.assignUserIds, ',');
              x.assignUserIds = [];
              _.each(ids, (id) => {
                x.assignUserIds.push(Number(id));
              });
            }
          });
        } else {
          res.data.stepList = this.form.stepList;
        }

        this.form = {
          ...res.data,
          comId: `${res.data.comId}`
        };
        // 编辑以后在获取人员
        this.getPeopleList(this.form.comId);
        this.open = true;
        this.title = '修改信息';
      });
    },
    enableItem(row) {
      sysCompanyWorkflowUpdate({
        id: row.id,
        dataStatus: row.positionStatus === 1 ? 2 : 1
      }).then((response) => {
        this.msgSuccess('修改成功');
        this.getList();
      });
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const params = _.cloneDeep(this.form);

          // 2 授权审批人 不需要审批人
          if (`${this.form.auditType}` === '2') {
            delete params.stepList;
          } else {
            _.each(params.stepList, (x) => {
              x.assignUserIds = _.join(x.assignUserIds, ',');
            });
          }

          if (this.form.id) {
            sysCompanyWorkflowUpdate(_.omit(params, 'dataStatus')).then((response) => {
              this.msgSuccess('修改成功');
              this.$router.back();
              // this.open = false;
              // this.getList();
            });
          } else {
            sysCompanyWorkflowSave(params).then((response) => {
              this.msgSuccess('新增成功');
              this.$router.back();
            });
          }
        }
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.id || this.ids;
      this.$confirm('确定要删除选中的记录？', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => sysCompanyRoleDelete([ids]))
        .then(() => {
          this.getList();
          this.msgSuccess('删除成功');
        })
        .catch(() => {});
    }
  }
};
</script>

<style lang="less" scoped>
.online-info-page {
  table {
    td {
      padding: 10px 5px;
      //margin:0 5px;
    }
  }

  .dialog-footer {
    width: 280px;
    margin: 10px auto;
  }

  /deep/ .quill-editor {
    height: 300px;
  }

  /deep/ .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  /deep/ .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  /deep/ .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
  }

  /deep/ .avatar {
    width: 100px;
    height: 100px;
    display: block;
  }
}
</style>
